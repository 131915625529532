@use '../_prefix.scss' as *;
@use '@ingka/variables/style.scss' as variables;
@use '@ingka-group-digital/nav-content-container/_mixins.scss' as *;
@include content-container($prefix);

.#{$prefix}content-container {
    margin: variables.$space-125 auto;
    @include variables.md {
        margin-top: 0;
    }
}
